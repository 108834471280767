import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import $ from 'jquery';
import 'jszip';
import 'pdfmake';
import Swal from 'sweetalert2';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';

export default class PeriodFees extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fees: null,
            rows: null,
            active: false,
            isClicked: false,
            Start: '',
            End: '',
            
        }
    }

    async componentDidMount() {
        var startDate = sessionStorage.getItem('sdate');

        var EndDate = sessionStorage.getItem('edate');
        this.setState({
          Start : startDate,
          End : EndDate,
        })

        const sendData = {
          Type: 'PeriodicFees',
          Sdate : startDate,
          Edate : EndDate
      }

      await axios.post("/API/View.php",sendData)
          .then((response) => {
            console.log(response.data.s + ' ' + response.data.e)
            this.setState({ fees : response.data.Fees });
          })
          .catch((error) => {
             console.log(error);
          });

      if (this.state.active === false) {
          setTimeout(() => {
              $('#example').DataTable(
                  {
                      dom: 'Bfrtip',
                      buttons: [
                      { extend: 'csvHtml5', footer: true },
                      { extend: 'print', footer: true,messageTop:
                      '<div style="text-align : center;"><h3>CIT Education Center</h3><p style="font-weight: bold;">Prop: Vivek Prajapati</p><h4>Fees Statement</h4><p style="font-weight: bold;margin-top: -10px;">For the period ' + this.state.Start +' To '+ this.state.End +'</p><div>' 
                      ,title:'',exportOptions: {
                        stripHtml: false
                    },}
                      ],
                      pageLength: 25,

                      "footerCallback": function ( row, data, start, end, display ) {
                          var api = this.api(), data;
               
                          // converting to interger to find total
                          var intVal = function ( i ) {
                              return typeof i === 'string' ?
                                  i.replace(/[\$,]/g, '')*1 :
                                  typeof i === 'number' ?
                                      i : 0;
                          };
               
                          // computing column Total of the complete result 
                          var monTotal = api
                              .column(5)
                              .data()
                              .reduce( function (a, b) {
                                  return intVal(a) + intVal(b);
                              }, 0 );

                             
                          $( api.column(4).footer() ).html('Total Fees : ');
                          $( api.column(5).footer() ).html(monTotal);
                         
                      }
                  }
              );
              $('#example td').css('white-space', 'initial');

              this.setState({ active: true });
          }, 100);
      }

        
    }

    printHandler(rno)
    {
        window.location.replace('/PrintFees/'+rno);
    }

  render() {
    return (
      <div class="content container-fluid">


                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="page-title">Periodic Fees</h3>
                            <h6>From : {this.state.Start} &nbsp;&nbsp;&nbsp; To : {this.state.End}</h6>
                            <br></br>
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><Link to="/PeriodicForm">Periodic Form</Link></li>
                                <li class="breadcrumb-item active">Periodic Statement</li>
                            </ul>
                        </div>
                        <div class="col-auto text-right float-right ml-auto">
                            <Link to="/StdStatementForm" class="btn btn-primary"><i class="fas fa-file-alt"></i> Student Fees Statement</Link>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-12">

                        <div class="card card-table">
                            <div class="card-body">
                                <div class="table-responsive">
                               
                                    <table class="table table-hover table-center mb-0 datatable wrap" id="example">
                                        <thead>
                                            <tr>
                                            
                                                <th>Actions</th>
                                                <th>R.No</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th>Student Name</th>
                                                <th>Deposit</th>
                                                <th>Balance</th>
                                                <th>Depositor</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        {this.state.fees && this.state.fees.map((result, index) =>
                                                <tr>
                                                    
                                                    <td>
                                                    
                                                                <button class="btn btn-sm bg-danger-light" id={result.Id} onClick={() => this.printHandler(`${result.Reciept_No}`)}>
                                                                <i class="fas fa-print"></i>
                                                                </button>
                                                                
                                                    </td>
                                                    <td style={{color:'Red',fontWeight:'bold'}}>{result.Reciept_No}</td>
                                                    <td>{result.Status === '0' ? <span className="badge badge-danger">Pending</span> : <span className="badge badge-success">Accepted</span>}</td>
                                                    <td>{result.Date}</td>
                                                    <td>{result.First_Name + ' ' + result.Last_Name}</td>
                                                    <td>{result.Deposit}</td>
                                                    <td>{result.Balance}</td>
                                                    <td>{result.Name}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>

                                                
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                       
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    )
  }
}
