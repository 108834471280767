import React, { Component } from 'react';
import {ImageURL} from './BaseURL';
import Swal from 'sweetalert2';
import { Link} from 'react-router-dom';
export default class Header extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
      }
    }
    componentDidMount() {
        var number = sessionStorage.getItem('Key');
        if(number === '') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Wrong attempt to Login at Header !! Please Re-Login & come back.',
            allowOutsideClick: false,
          })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.href = "https://citeducation.co.in/FacLogin.php";
              }
            })
        }
    
      }

    Logout = () =>
    {
        Swal.fire({
            title: 'Are you sure ?',
            text: "You would be logged off & Redirected to login page",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Log out!'
          }).then((result) => {
            if (result.isConfirmed) {
                sessionStorage.clear();
                window.location.href = "https://citeducation.co.in/FacLogin.php";
            }
          })
    }
  render() {
    return (
        <div className="header">
			
        <div className="header-left">
            <a href="#" className="logo">
                <img src={ImageURL +"assets/img/citlogo.png"} alt="Logo"/>
            </a>
            <a href="#" className="logo logo-small">
                <img src={ImageURL +"assets/img/logosmall.png"} alt="Logo" width="30" height="30" />
            </a>
        </div>
        
        <a href="javascript:void(0);" id="toggle_btn">
            <i className="fas fa-align-left"></i>
        </a>
        
        
        <a className="mobile_btn" id="mobile_btn">
            <i className="fas fa-bars"></i>
        </a>
       
        <ul className="nav user-menu">
 
            <li className="nav-item dropdown has-arrow">
                <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                    <span className="user-img"><img className="rounded-circle" src={ImageURL+"assets/img/faculty/"+this.props.Image} width="31" alt="Admin" /></span>
                </a>
                <div className="dropdown-menu">
                    <div className="user-header">
                        <div className="avatar avatar-sm">
                            <img src={ImageURL+"assets/img/faculty/"+this.props.Image} alt="Admin" className="avatar-img rounded-circle" />
                        </div>
                        <div className="user-text">
                            <h6>{this.props.Name}</h6>
                            <p className="text-muted mb-0">Admin</p>
                        </div>
                    </div>
                    <Link className="dropdown-item" onClick={this.Logout} to='#'>Logout</Link>
                </div>
            </li>

            
        </ul>

        
    </div>
    )
  }
}
