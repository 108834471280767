import React, { Component } from 'react';
import axios from "axios";
import { NavLink} from 'react-router-dom';

export default class PrintFees extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        Feesdata : null,
        Fwords : null,
      }
    }

    print = ()=>
  {
    window.print();
  }

   doConvert = (deposit)=>{
    let numberInput = deposit;
    
    let oneToTwenty = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ',
    'Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
    let tenth = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];

    if(numberInput.toString().length > 7) return this.setState({Fwords:'overlimit'});
    
    //let num = ('0000000000'+ numberInput).slice(-10).match(/^(\d{1})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  let num = ('0000000'+ numberInput).slice(-7).match(/^(\d{1})(\d{1})(\d{2})(\d{1})(\d{2})$/);
    
    if(!num) return;

    let outputText = num[1] != 0 ? (oneToTwenty[Number(num[1])] || `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}` )+' Million ' : ''; 
  
    outputText +=num[2] != 0 ? (oneToTwenty[Number(num[2])] || `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}` )+'Hundred ' : ''; 
    outputText +=num[3] != 0 ? (oneToTwenty[Number(num[3])] || `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`)+' Thousand ' : ''; 
    outputText +=num[4] != 0 ? (oneToTwenty[Number(num[4])] || `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) +'Hundred ': ''; 
    outputText +=num[5] != 0 ? (oneToTwenty[Number(num[5])] || `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]} `) : ''; 

    this.setState({Fwords:outputText});
}

  async componentDidMount()
  {
    const id = window.location.href.split('/')[4];
            const sendData = {
            Type:'FeesData',
            Id : id,
        }
        
        await axios.post("/API/View.php",sendData)
            .then((response) => {
            this.setState({ Feesdata : response.data.FD});
            this.doConvert(response.data.FD[0].Deposit);
            });
            

  }
  render() {
    return (
    <div>
{this.state.Feesdata && this.state.Feesdata.map((result, index) =>
<>
        <header>
	        <div>
	            <img alt="" src="/assets/img/1200px-US-NIH-CIT-Logo.svg.png" style={{height:'75px',width:'150px',float:'left'}}/>
	            <h1 style={{textAlign:'center',fontWeight:'bolder',fontSize:'44px',fontFamily: 'ALGERIAN',paddingBottom:'0%',marginBottom:'0%',marginTop:'0%'}}>CIT EDUCATION CENTER</h1>
            </div>
	        <h3 style={{textAlign:'right',fontFamily:'Brush Script MT',fontSize:'15px',fontWeight:'bolder'}}>The School of Programming Language</h3>
        </header>

<p style={{fontSize:'15px',width:'100%',fontWeight:'bolder',textAlign:'center'}}>Admission Open Basic-Computer, Tally, C, C++, Java, ASP.Net, PHP, Python,  DCA, PGDCA, MSc</p>
<hr />

<div style={{width:'100%',
        fontSize:'15px',
        fontWeight:'bolder'}}>

<div style={{width:'50%',
       float: 'left',
       fontSize: '15px',
       fontWeight: 'bolder'}}>
<table>
	<tr><td>Fee Receipt No : &nbsp;&nbsp;</td><td><label>{result.Reciept_No}</label></td></tr>

<tr><td>Enrollment No : &nbsp;&nbsp;</td><td> <label>{result.Enroll_No}</label></td></tr>


<tr><td>Next Installment : &nbsp;&nbsp;</td><td> <label>{result.Due_Date}</label></td></tr>
<tr><td>Net Fess : &nbsp;&nbsp;</td><td><label>{result.Net_Fees}</label></td></tr>	
<tr><td>Current Deposit : &nbsp;&nbsp;</td><td><label>{result.Deposit}</label></td></tr>

</table>
</div>
<div style={{width:'50%',
      float:'left',fontSize: '15px',
      fontWeight: 'bolder'}}>
	<table>
<tr><td>Date & Time : &nbsp;&nbsp;</td><td> <label>{result.Date}</label></td></tr>
<tr><td>Name : &nbsp;&nbsp;</td><td><label>{result.First_Name + ' ' + result.Last_Name}</label></td></tr>
<tr><td>&nbsp;&nbsp;</td><td><label></label></td></tr>
<tr><td>Subjects : &nbsp;&nbsp;</td><td><label>{result.Subjects}</label></td></tr>
<tr><td>Current Balance : &nbsp;&nbsp;</td><td><label>{result.Balance}</label></td></tr>
</table>
</div>
</div>

<hr />

<div style={{fontSize:'15px',float:'left',fontWeight:'bolder'}}>In Words- {this.state.Fwords} Rupees Only</div>
	<div style={{textAlign:'center',fontSize:'15px',float:'right',fontWeight:'bolder'}}>Admission at {result.Branch}  Branch</div>

<br />
<hr />

	<div style={{fontSize:'15px',
  float:'left',
   width: '35%',
   fontWeight:'bolder'}}>
	Director Name:<br />
	Mr.Vivek Prajapati
	</div> 
	<div style={{fontSize:'15px',
       float: 'left',
       width: '35%',
       fontWeight:'bolder'}}>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Submitted by :<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>{result.Name}</label>
	</div> 
	<div style={{textAlign: 'center',
  float: 'left',
  fontSize: '15px',
  width: '30%',
  fontWeight:'bolder'}}>
	________________<br />
	Seal &  Sign
	</div> 

<hr />
<footer>
	<div style={{textAlign:'center',
  fontSize:'15px',
  fontWeight:'bolder'}}>
   <u> Branch Address</u> <br />
		Near OM Honda,Bhanpur Chouraha,Peoples Mall,Bhopal,4620375<br></br>
		221,Near Elite School,Green Park Colony,DIG Bungalow,Bhopal,462001
	</div>
	<br />
	
<div style={{width:'50%',
       float: 'left',
       fontSize: '15px',
       fontWeight: 'bolder'}}>Contact:-9827688333,9300089990</div>
   
	<div style={{textAlign:'right',
  float: 'left',
  width: '50%',
  fontWeight:'bolder'}}>www.citeducation.co.in</div>

<hr />
</footer>

<div style={{textAlign:'center'}}>
<button onClick={this.print}>Print</button> &nbsp;&nbsp;&nbsp;

<a href='#'>
<button type="button" onClick={()=>window.history.back()}><i class="fa fa-chevron-left"></i>Back</button>
</a>
</div>
</>
)}
    </div>
    
    )
  }
}
