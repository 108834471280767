import React, { Component } from 'react'
import axios from "axios";
import {ImageURL} from '../BaseURL';
import $ from 'jquery';
import Swal from 'sweetalert2';
export default class AddFess extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        students : null,
        sinfo : false,
        SinfoFilled:null,
        StdBalance:null,
        StdNetfees:null,
        isClickedsearch:false,
        isClickeddeposit:false,
        btnTextsearch : "Search",
        btnTextdeposit : "Deposit",
      }
      this.Clear = this.Clear.bind(this);
    }
    select2PX(){
        window.$(document).ready(function() {
            window.$('#selUser').select2();
        });
    }
    async componentDidMount()
    {
        const sendData = {
            Type : 'SLFDep'
        }
        await axios.post("/API/View.php",sendData)
        .then((response)=>{
          this.setState({students : response.data.SL});
        });
        
        this.select2PX();
    }

    propchange = async(event)=> {
        event.preventDefault();
        if (this.state.sinfo===false)
        {
            this.setState({isClickedsearch:!this.state.isClickedsearch, btnText : "Processing "});
            const sendData = {
                Type : 'SInfoFD',
                Enroll:event.target.cate.value
            }
        await axios.post("/API/View.php",sendData)
        .then((response)=>{
          this.setState({SinfoFilled : response.data.SInfo});
          this.setState({sinfo:true,StdBalance:response.data.SInfo[0].Balance,StdNetfees:response.data.SInfo[0].Net_Fees});
          this.setState({isClickedsearch:!this.state.isClickedsearch,btnTextsearch:'Search'});
          
        });

        }
        
        if(this.state.sinfo===true)
        {
            this.setState({isClickeddeposit:!this.state.isClickeddeposit, btnTextdeposit : 'Processing'});
            var deposit= event.target.sdep.value;
            if(parseInt(this.state.StdBalance) < parseInt(deposit) || parseInt(deposit)===0)
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Deposit amount cannot be greater or 0. Please check the balance amount',
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                        this.setState({isClickeddeposit:!this.state.isClickeddeposit, btnTextdeposit : 'Deposit'});
                    }
                  })
                
            }
           
            else{
                const sendData = {
                    Type : 'Fees',
                    depositor:JSON.parse(sessionStorage.getItem('Key')),
                    Enroll:event.target.cate.value,
                    NetFees:this.state.StdNetfees,
                    Deposit:deposit,
                    Balance:this.state.StdBalance,
                    DueDate:event.target.ddate.value+' 23:59:59',
                    Email:event.target.semail.value
                }
                
                await axios.post("/API/Add.php",sendData)
                .then((response) => {
                    if (response.data.status === "Invalid") {
                      Swal.fire({
                        title: "Oops !! ",
                        text: "An Error Occurred !! Try after some time",
                        icon: "error",
                        button: "Back",
                        allowOutsideClick: false,
                      });
                    }
            
                    else if (response.data.status === "Valid") {
                      
                    Swal.fire({
                      title: "Success!",
                      text: "Fees Deposited Successfully! Please print it from Fees Collection page",
                      icon: "success",
                      allowOutsideClick: false,
                    })
                      .then((result) => {
                        if (result.isConfirmed) {
                          window.location.replace('/PeriodicForm')
                        }
                      })
            
                    }
            
                    else {
                     
                      Swal.fire({
                        title: "Oops !! ",
                        text: "Some Error Occurred !!",
                        icon: "error",
                        button: "Back",
                        allowOutsideClick: false,
                      });
                    }

                  })
                  .catch((error) => {
                    alert(error.message);
                  });
                  this.setState({isClickeddeposit:!this.state.isClickeddeposit, btnTextdeposit : "Deposit "});
            }
            
        }
        // else
        // {
        //     Swal.fire({
        //         icon: 'warning',
        //         title: 'Oops...',
        //         text: 'Please enter correct information !! ',
        //       })
        // }
    }

    Clear() {
        if (this.state.SinfoFilled)
        {
        this.setState({SinfoFilled : null});
        this.setState({sinfo:false});  
        }

    }
  render() {
    return (
        <div class="content container-fluid">
        
            {/* <!-- Page Header --> */}
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">Deposit Fees</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">Accounts</li>
                            <li class="breadcrumb-item active">Submit Fees</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- /Page Header --> */}
            <div class="row">

                <div class="col-sm-12">
                
                    <div class="card">
                        <div class="card-body">
                            <form method="POST" onSubmit={this.propchange} id='FeesForm'>
                                <div class="row">
                                    <div class="col-12">
                                        <h5 class="form-title"><span>Student Information</span></h5>
                                    </div>
                                    <div class="col-12 col-sm-6">
												<div class="form-group">
													<label style={{fontWeight:'bold'}}>Select Student info</label>

													<select class="form-control" name="cate" id='selUser' disabled={this.state.SinfoFilled?true:false}>
                                                    {this.state.students && this.state.students.map((result,index) =>
                                                        <option value={result.Enroll_No} key={result.Enroll_No}>
                                                            {index+1}.
                                                            {' ' + result.First_Name + ' '+result.Father_Name}
                                                           
                                                            
                                                             </option>
                                                    )}
                                                    </select>
												</div>
											</div>
                                            
											<div class="col-12 col-sm-6">
                                            <label></label><br/>
                                            <button type="submit" class="btn btn-primary" disabled ={this.state.isClickedsearch} style={{visibility:this.state.SinfoFilled?'hidden':'visible'}}>
                                    {this.state.isClickedsearch
                                    ? <span class="spinner-border spinner-border-sm mr-2" role="status"></span>
                                    : ""
                                    }
                                    
                                    {this.state.btnTextsearch}
                                    </button>
                                                
											</div>
                                    </div>
                                    
                                    {this.state.SinfoFilled && this.state.SinfoFilled.map((result,index) =>
                                    <div class="row" key={index}>
                                    
                                    <div className="col-12">
                                    <h5 className="form-title"><span>Student Information</span></h5>
                                </div>
                                <div className="col-12 col-sm-5">
                                    <div className="form-group">
                                        <label>Student Info</label><br />
                                        <label name='sname' style={{fontWeight:'bold'}}>
                                        <img src={ImageURL+"stdimages/"+result.Image} alt="Std Image" className="avatar-img rounded-circle" height='50px' width='50px' />
                                        &nbsp;&nbsp;&nbsp;
                                        {result.First_Name + ' '+ result.Father_Name}</label>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-2">
                                    <div className="form-group">
                                        <label>Enroll No</label><br />
                                        <label name='enroll' style={{fontWeight:'bold',color:'firebrick'}}>{result.Enroll_No}</label>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-5">
                                    <div className="form-group">
                                        <label>Email id</label>
                                        <input type="text" className="form-control" name='semail' required={true} defaultValue={result.Email}/>
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <h5 className="form-title"><span>Accounts Information</span></h5>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Net fees : &nbsp;&nbsp;</label>
                                        <label name='snet' style={{fontWeight:'bold', color:'Green'}} htmlFor='snet'>{result.Net_Fees}</label>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Balance fees : &nbsp;&nbsp;</label>
                                        <label name='sbal' style={{fontWeight:'bold',color:'Red'}}>{result.Balance}</label>
                                    </div>
                                </div>
                                
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Current Deposit*</label>
                                        <input type="text" className="form-control" name='sdep'required={true}/>
                                    </div>
                                </div>
                                
                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Next Installment*</label>
                                        <input type="date" className="form-control" name='ddate'required={true}/>
                                    </div>
                                </div>
                                
                                
                                <div className="col-12">
                                <button type="submit" class="btn btn-primary" disabled ={this.state.isClickeddeposit}>
                                    {this.state.isClickeddeposit
                                    ? <span class="spinner-border spinner-border-sm mr-2" role="status"></span>
                                    : ""
                                    }
                                    
                                    {this.state.btnTextdeposit}
                                    </button>
                                    &nbsp;&nbsp;&nbsp;
                                <button onClick={this.Clear} class="btn btn-primary">Clear</button>
                                </div>
                                
                                    </div>
                                    )}
                                    </form>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
    )
  }
}
