import React, { Component } from 'react'
import { Link} from 'react-router-dom';

export default class PeriodForm extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        isClicked : false,
        btnText : "View Fees",
      }
    }
    
    PeriodicHandler = async(event)=> {
        event.preventDefault();
        const StartDate = event.target.sdate.value;
        const EndDate = event.target.edate.value;
        sessionStorage.setItem('sdate', StartDate);
        sessionStorage.setItem('edate', EndDate);
        
        window.location.replace('/PeriodicFees');
    }
  render() {
    return (
     <div className="content container-fluid">
				
        
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col">
                    <h3 className="page-title">Select Fees Period</h3>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">Fees Collection</li>
                        <li className="breadcrumb-item active">Periodic Collection</li>
                    </ul>
                </div>
                <div class="col-auto text-right float-right ml-auto">
                            <Link to="/StdStatementForm" class="btn btn-primary"><i class="fas fa-file-alt"></i> Student Fees Statement</Link>
                        </div>
            </div>
        </div>
        
    
        <div className="row">
            <div className="col-sm-12">
            
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={this.PeriodicHandler} id='PeriodForm'>
                            <div className="row">
                               
                               

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Start Date*</label>
                                        <input type="date" className="form-control" name='sdate' id='sdate' required={true} />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>End Date*</label>
                                        <input type="date" className="form-control" name='edate' id='edate' required={true}/>
                                    </div>
                                </div>
                               
                               
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary" disabled ={this.state.isClicked}>
                                    {this.state.isClicked
                                    ? <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
                                    : ""
                                    }
                                    
                                    {this.state.btnText}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>							
            </div>					
        </div>					
    </div> 
    )
  }
}
