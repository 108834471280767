import React, { Component } from 'react'
import { Link} from 'react-router-dom';

export default class SideNavBar extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
      }
    }
  render() {
    return (
        <div>
		<div class="sidebar" id="sidebar">
                <div class="sidebar-inner slimscroll">
					<div id="sidebar-menu" class="sidebar-menu">
						<ul>
							<li class="menu-title"> 
								<span>Main Menu</span>
							</li>
							<li class= {this.props.pathname === "Home" ? "active" : ""}> 
								<Link to="/"><i class="fas fa-th-large"></i> <span>Dashboard</span></Link>
							</li>

							<li class= {this.props.pathname === "FeeDep" ? "active" : ""}> 
							<Link to="/FeesDeposit"><i class="fas fa-chalkboard-teacher"></i> <span>Deposit Fees</span></Link>
							</li>
							
							<li class= {this.props.pathname === "PeriodicForm" ? "active" : ""}> 
							<Link to="/PeriodicForm"><i class="fas fa-user-check"></i> <span>Fees Collection</span></Link>
							</li>
							{
								sessionStorage.getItem('ER') === '1' ? <li class= {this.props.pathname === "FeeConf" ? "active" : ""}> 
								<Link to="/FeeConf"><i class="fas fa-hotel"></i> <span>Confirm Fees</span></Link>
								</li> : null
							}
							

						</ul>
					</div>
                </div>
				
      </div>
	  </div>
    )
  }
}
