import React, { Component } from 'react'
import axios from "axios";
import Swal from 'sweetalert2';
import { Link} from 'react-router-dom';
export default class Homepage extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          TodayR : null,
          MonthR : null,
          TopFees : null,
          TodayDues : null
        }
      }
      async componentDidMount()
    {
        const sendData = {
            Type : 'Dashboard',
          }
        await axios.post("/API/View.php",sendData)
            .then((response) => {
                this.setState({
                    TodayR: response.data.TR,
                    MonthR: response.data.MR,
                    TopFees : response.data.TF,
                    TodayDues : response.data.TD,
                    });
            });
            
    }
    SendMail()
    {
        Swal.fire({
            title: 'Are you sure?',
            text: "Please Send notification if you haven't sent today!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, send it!'
          })
          .then(async(result) => {
            if (result.isConfirmed) {
                const sendData={
                    Type: 'Notifications',
                }
                await axios.post("/API/Update.php",sendData)
              .then((response)=>{
                if(response.data.status === "valid")
                {
                    
              Swal.fire({
                title: "Success!",
                text: "Notifications sent Successfully via mail",
                icon: "success",
                allowOutsideClick: false,
            })
                }
                else
                {
        Swal.fire({
          title: "Oops !! ",
          text: "Notifications not sent. Try again after sometime!!",
          icon: "error",
          button: "Back",
          allowOutsideClick: false,
        });
        
      }

        })
      
      }
    })

    }
  render() {
    return (
        <div class="content container-fluid">
            {/* <!-- Page Header --> */}
            <div class="page-header">
                <div class="row">
                    <div class="col-sm-12">
                        <h3 class="page-title">Welcome {this.props.Name} !</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item active">Enhanced Fees Dashboard</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- /Page Header --> */}

            {/* <!-- Overview Section --> */}
            <div class="row">
                <div class="col-xl-3 col-sm-6 col-12">
                    <div class="card bg-one">
                        <div class="card-body">
                            <div class="db-widgets d-flex justify-content-between align-items-center">
                                <div class="db-icon">
                                <i class="fas fa-rupee-sign"></i>
                                </div>
                                <div class="db-info">
                                    <h3>{this.state.TodayR === 0 ? '0' : this.state.TodayR}</h3>
                                    <h6>Today Rev.</h6>
                                </div>										
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-sm-6 col-12">
                    <div class="card bg-two">
                        <div class="card-body">
                            <div class="db-widgets d-flex justify-content-between align-items-center">
                                <div class="db-icon">
                                <i class="fas fa-rupee-sign"></i>
                                </div>
                                <div class="db-info">
                                    <h3>{this.state.MonthR === 0 ? '0' : this.state.MonthR}</h3>
                                    <h6>Month Rev.</h6>
                                </div>										
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /Overview Section -->				 */}
 
            <div class="row">
                <div class="col-md-6 d-flex">						
        
                    <div class="card flex-fill">
                        <div class="card-header">
                            <h5 class="card-title" style={{color:'darkgoldenrod'}}>Recent Deposits</h5>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-hover table-center">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>R.No</th>
                                            <th>Student Name</th>
                                            <th class="text-center">DOD</th>
                                            <th class="text-center">AMT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.TopFees && this.state.TopFees.map((result,index) =>
                                        <tr key={index+1}>
                                        <td class="text-nowrap">
                                            <div>{result.Reciept_No}</div>
                                        </td>
                                        <td>{result.First_Name + ' '+result.Last_Name}</td>
                                        <td class="text-center">{result.Date}</td>
                                        <td class="text-center" style={{color:'forestgreen'}}>{result.Deposit}</td>
                                    </tr>
                                    )}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
        
                </div>

                <div class="col-md-6 d-flex">				
                    <div class="card flex-fill">
                        <div class="card-header">
                            <h5 class="card-title" style={{color:'darkorchid'}}>Today Dues</h5>
                            
                            <span class="list-inline-group text-right">
							<button type='button' class='btn btn-warning btn-sm' id="sendmail" name="sendmail" onClick={() => this.SendMail()}><i class="fa fa-bell" aria-hidden="true"></i> Send Notification</button>
							</span>

                        </div>
                        
                        <div class="card-body">
                        <div class="table-responsive">
                                <table class="table table-hover table-center">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Due Date</th>
                                            <th>Student Name</th>
                                            <th class="text-center">Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.TodayDues && this.state.TodayDues.map((result,index) =>
                                        <tr key={index+1}>
                                        <td class="text-nowrap" style={{color:'red',fontWeight:'bold'}}>
                                            <div>{result.Date}</div>
                                        </td>
                                        <td>{result.First_Name + ' '+result.Last_Name}</td>
                                        <td class="text-center" style={{color:'darkorchid'}}>{result.Balance}</td>
                                    </tr>
                                    )}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
        
                </div>
            </div>

            <li> 
								<Link to="/"><i class="fas fa-th-large"></i> <span>Dashboard</span></Link>&nbsp;&nbsp;
                <Link to="/FeesDeposit"><i class="fas fa-chalkboard-teacher"></i> <span>Deposit Fees</span></Link>&nbsp;&nbsp;
                <Link to="/PeriodicForm"><i class="fas fa-user-check"></i> <span>Fees Collection</span></Link>&nbsp;&nbsp;
                {
								sessionStorage.getItem('ER') === '1' ? 
								<Link to="/FeeConf"><i class="fas fa-hotel"></i> <span>Confirm Fees</span></Link>
								 : null
							}
							</li>
        </div>
    )
  }
}
