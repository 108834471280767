import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import Header from './Header';
import SideNavbar from './SideNavBar';
import HomePage from './Homepage';
import Footer from './Footer';
import AddFess from './Deposit/AddFess';
import FeesConfirm from './Confirmation/FeesConfirm';
import PeriodForm from './Collection/PeriodForm';
import PeriodFees from './Collection/PeriodFees';
import PrintFees from './Collection/PrintFees';
import StudentStatementForm from './Collection/StudentStatementForm';
import StudentStatement from './Collection/StudentStatement';

export default class Container extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        Aname: "",
        AImage: "",
      }
    }

     componentDidMount() {
        
        const query = new URLSearchParams(window.location.search);
        var token = query.get('Key');
        var number = token;
        var token1 = query.get('ER');
        if(token === null || token ==='')
        {
          number = sessionStorage.getItem('Key');  
        }
        else
        {
          JSON.stringify(sessionStorage.setItem('Key',number));
          sessionStorage.setItem('ER', token1);
        }
  
        if(number!==null)
        {
          const sendData = {
            Mobile: number,
            Type : "details",
          }
          axios.post("/API/Login.php",sendData)
            .then((response) => {
              if (response.data.status === "Invalid") {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong! User is invalid or please try again login to the faculty module',
                  allowOutsideClick: false
                })
                  .then((result) => {
                    if (result.isConfirmed) {
                    //   window.location.replace("/");
                    window.location.href = 'https://citeducation.co.in/FacLogin.php';
                    }
                  })
              }
    
              else if (response.data.status === "Valid") {
                this.setState({
                  Aname: response.data.Name,
                  AImage: response.data.Image,
                });
                JSON.stringify(sessionStorage.setItem('Key', number));
              }
    
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong & Please retry!',
                  allowOutsideClick: false
    
                })
                  .then((result) => {
                    if (result.isConfirmed) {
                    //   window.location.replace("/");
                      window.location.href = 'https://citeducation.co.in/FacLogin.php';
                    }
                  })
              }
    
            })
            .catch((error) => {
              alert(error.message);
            });
        }
        else
        {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong! Wrong Attempt to Login !!',
            allowOutsideClick: false
          })
            .then((result) => {
              if (result.isConfirmed) {
              //   window.location.replace("/");
              window.location.href = 'https://citeducation.co.in/FacLogin.php';
              }
            })
        }
      }

  render() {
    return (
        <Router>
        <div class="main-wrapper">
          <Routes>
            <Route path="/" element={
              <>
               <Header Name={this.state.Aname} Image={this.state.AImage}></Header>
                <SideNavbar pathname="Home"></SideNavbar>
                <div class="page-wrapper">
                  <HomePage Name={this.state.Aname}></HomePage>
                  <Footer></Footer>
                </div>
              </>
            } />

            <Route path="/FeesDeposit" element={
              <>
               <Header Name={this.state.Aname} Image={this.state.AImage}></Header>
                <SideNavbar pathname="FeeDep"></SideNavbar>
                <div class="page-wrapper">
                  <AddFess></AddFess>
                  <Footer></Footer>
                </div>
              </>
            } />

            <Route path="/PeriodicForm" element={
              <>
               <Header Name={this.state.Aname} Image={this.state.AImage}></Header>
                <SideNavbar pathname="PeriodicForm"></SideNavbar>
                <div class="page-wrapper">
                 <PeriodForm></PeriodForm>
                  <Footer></Footer>
                </div>
              </>
            } />

            <Route path="/PeriodicFees" element={
              <>
               <Header Name={this.state.Aname} Image={this.state.AImage}></Header>
                <SideNavbar pathname="PeriodicForm"></SideNavbar>
                <div class="page-wrapper">
                 <PeriodFees></PeriodFees>
                  <Footer></Footer>
                </div>
              </>
            } />

              <Route path="/StdStatementForm" element={
              <>
               <Header Name={this.state.Aname} Image={this.state.AImage}></Header>
                <SideNavbar pathname="PeriodicForm"></SideNavbar>
                <div class="page-wrapper">
                 <StudentStatementForm></StudentStatementForm>
                  <Footer></Footer>
                </div>
              </>
            } />

              <Route path="/StdStatement" element={
              <>
               <Header Name={this.state.Aname} Image={this.state.AImage}></Header>
                <SideNavbar pathname="PeriodicForm"></SideNavbar>
                <div class="page-wrapper">
                 <StudentStatement></StudentStatement>
                  <Footer></Footer>
                </div>
              </>
            } />
            {sessionStorage.getItem('ER') === '1' ? 
            <Route path="/FeeConf" element={
              <>
               <Header Name={this.state.Aname} Image={this.state.AImage}></Header>
                <SideNavbar pathname="FeeConf"></SideNavbar>
                <div class="page-wrapper">
                <FeesConfirm></FeesConfirm>
                  <Footer></Footer>
                </div>
              </>
            } /> : null}
              

              <Route path={'/PrintFees/:Id'} element={
                <>
                <PrintFees></PrintFees>  
               </>

            } />

              <Route path="*" element={
              <>
               <p>Page not Found !!</p>
              </>
            } />

          </Routes>
        </div>


      </Router>
    )
  }
}

