import Container from "./Components/Container"
import React from 'react'
import ReactDOM from 'react-dom'
function App() {
  return (
    <div>
      <Container></Container>
    </div>
  );
}

export default App;
