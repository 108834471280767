import React, { Component } from 'react'
import axios from "axios";

export default class StudentStatementForm extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
          isClicked : false,
          btnText : "View Fees",
          students : null,
        }
      }
      
      async componentDidMount()
      {
        const sendData = {
            Type : 'SLFDep'
        }
        await axios.post("/API/View.php",sendData)
        .then((response)=>{
          this.setState({students : response.data.SL});
        });
        
        this.select2PX();
      }

      PeriodicHandler = async(event)=> {
          event.preventDefault();
          const StartDate = event.target.sdate.value;
          const EndDate = event.target.edate.value;
          const stdEnroll = event.target.cate.value;
          sessionStorage.setItem('sdate', StartDate);
          sessionStorage.setItem('edate', EndDate);
          sessionStorage.setItem('enroll', stdEnroll);
          
          window.location.replace('/StdStatement');
      }

      select2PX(){
        window.$(document).ready(function() {
            window.$('#selUser').select2();
        });
    }
  render() {
    return (
        <div className="content container-fluid">
				
        
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col">
                    <h3 className="page-title">Select Student Fees Period</h3>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">Fees Collection</li>
                        <li className="breadcrumb-item active">Student Collection</li>
                    </ul>
                </div>
            </div>
        </div>
        
    
        <div className="row">
            <div className="col-sm-12">
            
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={this.PeriodicHandler} id='PeriodForm'>
                            <div className="row">
                               
                               
                            <div class="col-12 col-sm-6">
												<div class="form-group">
													<label>Select Student*</label>

													<select class="form-control" name="cate" id='selUser'>
                                                    {this.state.students && this.state.students.map((result,index) =>
                                                        <option value={result.Enroll_No} key={result.Enroll_No}>
                                                            {index+1}.
                                                            {' ' + result.First_Name + ' '+result.Father_Name}
                                                           
                                                            
                                                             </option>
                                                    )}
                                                    </select>
												</div>
											</div>
                                
                                            <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input type="date" className="form-control" name='sdate' id='sdate'/>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input type="date" className="form-control" name='edate' id='edate'/>
                                    </div>
                                </div>
                               
                               
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary" disabled ={this.state.isClicked}>
                                    {this.state.isClicked
                                    ? <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
                                    : ""
                                    }
                                    
                                    {this.state.btnText}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>							
            </div>					
        </div>					
    </div> 
    )
  }
}
