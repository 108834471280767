import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios, { AxiosError } from "axios";
import $, { error } from 'jquery';
import Swal from 'sweetalert2';

export default class FeesConfirm extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         clist: null,
         arr : [],
      }
      this.Checkclick = this.Checkclick.bind(this);
    }

    async componentDidMount() {
        const sendData = {
            Type: 'CList',
        }
        await axios.post("/API/View.php",sendData)
            .then((response) => {
            this.setState({ clist: response.data.CL});
            })
            .catch((error) => {
              alert("Error");
            });
    }

    Checkclick(rno)
    {
        if(this.state.arr.includes(rno))
        {
            var index = this.state.arr.indexOf(rno);
            if (index > -1) {
                this.state.arr.splice(index, 1);
            }
        }
        else
        {
            this.state.arr.push(rno);
        }
        
    }

    fAccept()
    {
        Swal.fire({
            title: 'Are you sure?',
            text: "Please Accept these fees if you have recieved it !",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Accept it!'
          })
          .then(async(result) => {
            if (result.isConfirmed) {
                const sendData={
                    Type: 'UFAccept',
                    checkedlist : this.state.arr,
                }
              await axios.post("/API/Update.php",sendData)
              .then((response)=>{
                console.log(response);
                if(response.data.status === 'valid')
                {
                    
              Swal.fire({
                title: "Success!",
                text: "Fees Accepted Successfully!",
                icon: "success",   
            })
            .then(async(result) => {
                const sendData1={
                    Type: 'CList',
                }
              if (result.isConfirmed) {
                await axios.post("/API/View.php",sendData1)
                  .then((response)=>{
                    this.setState({clist : response.data.CL},()=>{
                      window.location.reload();
                    });
                  })
              } 
            })
                }
                else
                {
                  Swal.fire({
                    title: "Oops !! ",
                    text: "Fees not Accepted. Kindly try again after sometime!!",
                    icon: "error",
                  });
                  console.log(error);
        
      }

        })
      
      }
    })
            
    }

    UpdateFees(rno,dep)
    {
        const RNO = rno;
        const Dep = dep;
        Swal.fire({
            title: 'Edit Student Fees',
            html: `
            <label>Reciept Number : </label><label>${RNO}</label><br />
            <label>Deposit Amount : </label><input type="text" id="dep" class="swal2-input" placeholder="Deposit Amount" value=${Dep}>
            `,
            confirmButtonText: 'Update Fees',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
        .then(async(result) => {
            if (result.isConfirmed) {
                const CDep = document.getElementById('dep').value;
                if(CDep === Dep)
                {
                Swal.fire({
                    title: "Oops !! ",
                    text: "Updated Fees cannot be equal to deposit fees",
                    icon: "error",
                    button: "Back",
                    allowOutsideClick: false,
                  });
                }
                else{
                    const sendData={
                        Type: 'UFees',
                        CDep : CDep,
                        RNO : RNO,
                        Pdep : Dep,
                    }   
                    await axios.post("/API/Update.php",sendData)
              .then((response)=>{
                if(response.data.status === "valid")
                {
                    
              Swal.fire({
                title: "Success!",
                text: "Fees Updated Successfully!",
                icon: "success",   
            })
            .then(async(result) => {
                const sendData1={
                    Type: 'CList',
                }
              if (result.isConfirmed) {
                await axios.post("/API/View.php",sendData1)
                  .then((response)=>{
                    this.setState({clist : response.data.CL},()=>{
                      window.location.reload();
                    });
                  })
              } 
            })
                }
                else
                {
        Swal.fire({
          title: "Oops !! ",
          text: "Fees not Updated. Try again after sometime!!",
          icon: "error",
          button: "Back",
        });
        
                }

        }) 
                }
      }
    })
        
    }

  render() {
    return (
        <div class="content container-fluid">


        <div class="page-header">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="page-title">Fees to be Confirmed</h3>
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                        <li class="breadcrumb-item active">Confirm Fees</li>
                    </ul>
                </div>
                <div class="col-auto text-right float-right ml-auto">
                    <div class="btn-group">
                    <button type="button" class="btn btn-primary" onClick={() => this.fAccept()}><i class="fas fa-check-circle"></i> Accept Fees</button>
                    </div>
                </div>
            </div>
        </div>
        <p style={{color:'red'}}>Note: <br />
        1. To Accept the Fees : Select the multiple checkbox and click on Accept button<br />
        2. Editing the Fees : Make sure this is the latest Fees of student as this will impact the Balance
        
        </p>

        <div class="row">
            <div class="col-sm-12">

                <div class="card card-table">
                    <div class="card-body">
                        <div class="table-responsive">

                            <table class="table table-hover table-center mb-0 datatable wrap" id="example">
                                <thead>
                                    <tr>
                                        
                                        <th>Action</th>
                                        <th>R.No</th>
                                        <th>Status</th>
                                        <th>Depositor</th>
                                        <th>Date</th>
                                        <th>Student Name</th>
                                        <th>Deposit</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.clist && this.state.clist.map((result, index) =>
                                        <tr key={index}>
                                            
                                            <td>
                                               <input type='checkbox' value={result.Reciept_No} id={result.Reciept_No} onClick={() => this.Checkclick(`${result.Reciept_No}`)}/>
                                               &nbsp;&nbsp;&nbsp;&nbsp;
                                               <button class="btn btn-sm bg-success-light mr-2" onClick={() => this.UpdateFees(`${result.Reciept_No}`,`${result.Deposit}`)}>
                                                <i class="fas fa-pen"></i>
                                                </button>
                                            </td>
                                            <td style={{fontWeight:'bold',color:'darkblue'}}>{result.Reciept_No}</td>
                                            <td><span className="badge badge-danger">Pending</span></td>
                                            <td>{result.Name}</td>
                                            <td>{result.Date}</td>
                                            <td>{result.First_Name + ' '+ result.Last_Name}</td>
                                            <td style={{fontWeight:'bold',color:'darkred'}}>{result.Deposit}</td>
                                            
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>

                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}
