import React from 'react'

export default function Footer() {
  return (
    <>
    <footer>
            <p>Copyright © {new Date().getFullYear()} CIT Education Center. Made by Husain</p>					
        </footer>
        

							
    </>  
  )
}
